// Purpose: This file contains the code for the home page of the application.
import styles from './home.module.css'
import React from 'react';

function Home() {

  return (
   <React.Fragment>
      <header className={styles.header}>
        <h1 className={styles.title}>
          Vision Assistive Tech Database
        </h1>
        <h2 className={styles.subtitle}>by CCAVA</h2>
        <nav className={styles.nav}>
          <a href="/design_1">
            <button className={styles.nav_button}>View Design 1</button>
          </a>
          {/*
          <a href="/design_2">
            <button className={styles.nav_button}>View Design 2</button>
          </a>
  */}
        </nav>
      </header>
      <main className={styles.main}>
         <div className={styles.info}>
         <h1 className={styles.p}>This Page is Under Construction.</h1>
         <p className={styles.p}>Please view the database designs at the buttons in the header, and check back later for more content and additional tools!</p>
         </div>
         <p className={styles.attr}>Image by <a href="https://pixabay.com/users/davidrockdesign-2595351/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1409025">David</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=1409025">Pixabay</a></p>
      </main>
      <footer className={styles.footer}>
         ©CCAVA 2024
      </footer>
   </React.Fragment>
  );
}

export default Home;


